export default class RequestError extends Error {
    constructor(method, url, xhr, message = `Request failed: ${method} ${url} [${xhr.status}]`) {
        super(message);
        this.method = method;
        this.url = url;
        this.status = xhr.status;
        this.readyState = xhr.readyState;
        this.text = xhr.responseText;
        this.json = xhr.responseJSON;
    }
}
