/* global process */
export * from 'vue/dist/vue.runtime.esm-bundler.js';

import { createApp as createVueApp } from 'vue/dist/vue.runtime.esm-bundler.js';
import { attachErrorHandler } from "@sentry/vue";

if (process.env.NODE_ENV !== 'production') {
    window.VUE_DEVTOOLS_CONFIG = {
        openInEditorHost: 'http://localhost:14248/'
    };
}

export const VueSentry = {
    install(app) {
        attachErrorHandler(app, { logErrors: true });
    },
};

export function createApp(...args) {
    return createVueApp(...args).use(VueSentry);
}
