import $ from "jquery";

import toNativePromise from "JIX/utils/toNativePromise.js";
import RequestError from "JIX/errors/RequestError.js";
import ApiValidationError from "JIX/errors/ApiValidationError.js";
import ApiConflictError from "JIX/errors/ApiConflictError.js";
import { unref } from 'vue';

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function uniqueArray(array) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
}


/**
 * Return a string with the concatenation of individual name parts. Expects an
 * object with the properties `fullname`, `middlename` and `lastname` (which
 * may be undefined or null).
 */
export function concatName({ firstname, middlename, lastname }) {
    return [firstname, middlename, lastname].join(" ").trim();
}

/**
 * Performs named substitution of parameters.
 * @param {string} str - The format string
 * @param {Object} args - A map from named parameter to string
 * @returns {string} Returns the format-substituted string
 *
 * Replaces all occurences of the key.
 *
 * Equivalent to __x() from Gettext, * but without translations.
 *
 * @example
 * format("Hello {name}, you {title}!", { name: "Dave", title: "chap" })
 */
export function format(str, args) {
    return Object.keys(args).reduce(
        (acc, key) => acc.replace(new RegExp("{" + key + "}", 'g'), args[key]),
        str
    );
}

export function getCurrencyFormatter(currency, locale = getLocale()) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits: 0 });
}

export function getPercentFormatter(locale = getLocale()) {
    return new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getNumberFormatter(locale = getLocale()) {
    return new Intl.NumberFormat(locale);
}

const byteConvRatio = {
    'B':  0,
    'KB': 1,
    'MB': 2,
    'GB': 3,
    'TB': 4,
};
export function formatByteSize(size, toType = 'MB', fromType = 'B') {
    let ratio = byteConvRatio[fromType] - byteConvRatio[toType];
    return (size * Math.pow(1024, ratio)).toFixed(2);
}

export function formatBytesAuto(size) {
    let resSize = size;
    let resUnit = 'B';
    for (const [unit, power] of Object.entries(byteConvRatio)) {
        let tmp = size / (1024 ** power);
        if (tmp < 1) {
            break;
        }
        resSize = tmp;
        resUnit = unit;
    }
    resSize = new Intl.NumberFormat(getLocale(), { maximumFractionDigits: 1 }).format(resSize);
    return `${resSize} ${resUnit}`;
}

/**
 * Performs API call as a promise
 * @param {string} method - HTTP method for the the API call
 * @param {string} url - Endpoint for API call
 * @param {Object} data - Data associated with API call
 * @param {Object} options - Optional options object
 * @returns {Promise} - response from API endpoint
 */
export function api(method, url, data = {}, options = {}) {
    const json = options.json;
    delete options.json;
    const ajaxOptions = {
        method,
        url,
        data,
        traditional: true,
        headers: {},
        ...options,
    };
    if (window.Stash && window.Stash.common && window.Stash.common.csrf_token) {
        ajaxOptions.headers["X-CSRF-Token"] = window.Stash.common.csrf_token;
    }
    const hasRequestBody = !['GET', 'HEAD'].includes(method);
    if (json && hasRequestBody) {
        ajaxOptions.data = JSON.stringify(data);
        ajaxOptions.contentType ||= 'application/json';
    }

    return toNativePromise($.ajax(ajaxOptions))
        .catch(xhr => {
            if (xhr.status === 422) {
                throw new ApiValidationError(method, url, xhr);
            } else if (xhr.status === 409) {
                throw new ApiConflictError(method, url, xhr);
            } else {
                throw new RequestError(method, url, xhr);
            }
        });
}

/**
 * Construct a custom API utility function. The returned function works as the
 * api() utility with the following differences:
 *   - The `url` argument can be either a string or an array. If it is an array
 *     the elements are joined with slashes.
 *   - If the `url` argument doesn't start with a slash it is prefixed with the
 *     `baseUrl` given to the `makeApi` function.
 *   - The `baseOptions` object given to the `makeApi` function is merged with
 *     the options given to the returned api function with the latter having
 *     priority.
 */
export function makeApi(baseUrl, baseOptions = {}) {
    if (!baseUrl.endsWith('/')) {
        baseUrl += '/';
    }
    return function _api(method, endpoint, data = {}, options = {}) {
        options = { ...baseOptions, ...options };
        const path = Array.isArray(endpoint) ? endpoint.join("/") : endpoint;
        const url = path.startsWith('/') ? path : baseUrl + path;
        return api(method, url, data, options);
    };
}

export function loadScript(url, options = {}) {
    return new Promise((resolve, reject) => {
        const parentElement = options.parent || document.head;

        const script = document.createElement('script');
        script.src = url;

        script.async = !!(options.async);
        script.defer = !!(options.defer);

        script.onload = resolve;
        script.onerror = reject;

        parentElement.appendChild(script);
    });
}


export function getLocale({ defaultLocale = 'en-GB', element = null } = {}) {
    element ||= document.documentElement;
    do {
        if (element.getAttribute('lang')) {
            return element.lang;
        } else {
            element = element.parentElement;
        }
    } while (element);
    return defaultLocale;
}

export function randomInteger(min = 0, max = Number.MAX_SAFE_INTEGER) {
    return Math.random() * (max - min) + min;
}

export function stripHtml(html) {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent;
}

export function truncateString(str, maxLength, replacement = '…') {
    if (str.length <= maxLength) {
        return str;
    }
    const cut = maxLength - replacement.length;
    return [...str].slice(0, cut).join('') + replacement;
}

export function setDocumentTitle(title) {
    if (title) {
        let titleRaw = unref(title);
        titleRaw = Array.isArray(titleRaw) ? titleRaw : [titleRaw];
        document.title = [...titleRaw, window.Stash.common.sitename].join(" | ");
    } else {
        document.title = window.Stash.common.sitename;
    }
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function setCanonicalUrl(url) {
    let canonical = document.querySelector('link[rel=canonical]');
    if (!canonical) {
        canonical = document.createElement('link');
        canonical.setAttribute('rel', 'canonical');
        document.head.append(canonical);
    }
    canonical.setAttribute('href', url);

    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
        ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        document.head.append(canonical);
    }
    ogUrl.setAttribute('content', url);

    const updateHreflangElem = (elem) => {
        if ([window.Stash.common.lang_native, 'x-default'].includes(elem.hreflang)) {
            elem.href = url;
        } else {
            const localUrl = new URL(url, location.href);
            localUrl.searchParams.set('lang', elem.hreflang);
            elem.href = localUrl;
        }
    };

    const foundHreflang = new Set();
    const expectedHreflang = new Set([...window.Stash.common.lang_available, 'x-default']);
    document.querySelectorAll('link[rel=alternate][hreflang]').forEach(elem => {
        foundHreflang.add(elem.hreflang);
        updateHreflangElem(elem);
    });

    foundHreflang.difference(expectedHreflang).forEach(extra => {
        document.querySelector(`link[rel=alternate][hreflang=${extra}]`)?.remove();
    });
    expectedHreflang.difference(foundHreflang).forEach(missing => {
        const elem = document.createElement('link');
        elem.hreflang = missing;
        elem.rel = 'alternate';
        updateHreflangElem(elem);
        document.head.append(elem);
    });
}

export function setMetaDescription(value) {
    let elem = document.querySelector('meta[name=description]');
    if (value) {
        if (!elem) {
            elem = document.createElement('meta');
            elem.name = 'description';
            document.head.append(elem);
        }
        elem.content = value;
    } else {
        elem?.remove();
    }
}
