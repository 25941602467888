/**
 * Convert a jQuery Deferred object into a native Promise.
 *
 * NOTE: If the Deferred object is a jQuery ajax result then if the request
 * fails the rejection value will be a jQuery XHR object, not an Error object.
 * If the rejection is not handled that will result in a Sentry warning about
 * "Non-error exception captured". The caller of this function should handle
 * the error immediately or convert it to an Error before rethrowing.
 */
export default function toNativePromise(jqDeferred) {
    return new Promise((resolve, reject) => {
        jqDeferred.then(resolve, reject);
    });
}
