/* eslint-disable no-gettext-mismatches */

import { format } from 'JIX/utils';

const translations         = {};
const translations_context = {};
const translations_plural  = {};

export const config = { ignoreMissingTranslations: true };

export function add([msgid, is_plural, msgstr, context]) {
    if (context) {
        translations_context[msgid] || (translations_context[msgid] = {});
        translations_context[msgid][context] = msgstr;
    } else if (is_plural) {
        translations_plural[msgid[0]] || (translations_plural[msgid[0]] = {});
        translations_plural[msgid[0]][msgid[1]] = msgstr;
    } else {
        translations[msgid] = msgstr;
    }
}

export function add_many(objs) {
    objs.forEach((obj) => add(obj));
}

export function __(str) {
    const trans = translations[str];
    if (!trans) {
        if (!config.ignoreMissingTranslations) {
            console.error("Missing translation for '" + str + "'");
        }
        return str;
    }
    return trans;
}

export function __n(str1, str2, n) {
    let trans = (translations_plural[str1] || {})[str2];
    if (!trans || !trans[0] || !trans[1]) {
        if (!config.ignoreMissingTranslations) {
            console.error("Missing plural translation for '" + str1 + "', '" + str2 + "'");
        }
        trans = { '0': str1, '1': str2 };
    }

    return trans[n === 1 ? 0 : 1]; // TODO We don't support the Plural-Forms stuff. It doesn't matter for now.
}

export function __x(str, args) {
    const trans = __(str);
    return format(trans, args);
}

export function __nx(str1, str2, n, args) {
    const trans = __n(str1, str2, n);
    return format(trans, args);
}

export function __p(context, str) {
    let trans = (translations_context[str] || {})[context];
    if (!trans) {
        if (!config.ignoreMissingTranslations) {
            console.error("Missing translation for '" + str + "' (ctx: '" + context + "')");
        }
        return str;
    }
    return trans;
}

export function N__(str) {
    return str;
}

export function __N(str) {
    return __(str);
}
